interface GAEventParams {
  context?: string
  subcontext?: string
}

/**
 * @description Track events in Google Analytics.
 */
export function useAnalytics() {
  function trackEvent(eventName: string, eventParams?: GAEventParams): void {
    useTrackEvent(eventName, eventParams)
  }
  function trackOrderingAppEvent(eventName: string, eventParams?: Record<string, any>): void {
    useTrackEvent(eventName, eventParams)
  }
  return { trackEvent, trackOrderingAppEvent }
}

export function gaEventAccordionExpanded(): void {
  const { trackEvent } = useAnalytics()

  trackEvent('accordion_expanded')
}

export function gaEventCateringClicked(context: 'cta' | 'details'): void {
  const { trackEvent } = useAnalytics()

  trackEvent('catering_clicked', { context })
}

export function gaEventGroupBookingClicked(context: 'cta' | 'details'): void {
  const { trackEvent } = useAnalytics()

  trackEvent('group_booking_clicked', { context })
}
